<template>
  <!-- 数据看板>学生管理看板 -->
  <div class="dict">
    <div class="boardTitle">学生管理看板</div>
    <div class="container">
      <div class="container-first">
        <!-- 患病率/发病率 -->
        <div class="echartsItem container-left">
          <div class="echartsType" style="margin-bottom: 0.26042vw">
            <p class="echartsTitle">患病率&发病率
              <el-tooltip placement="top">
                <div slot="content">
                  本月累计<br/>
                  患病率=本月患病总人次/本月出勤总人次；<br/>
                  发病率=生病的人数/班级总人数
                </div>
                <span><img src="@/assets/img/wh.png" alt=""></span>
              </el-tooltip>
            </p>
          </div>
          <div class="chartsContent" v-show="Object.keys(morChartData).length">
            <div id="morChart"></div>
          </div>
          <div class="noEachartData" v-if="!Object.keys(morChartData).length">
            暂无数据
          </div>
        </div>
        <!-- 学生出险情况 -->
        <div class="echartsItem container-middle">
          <div class="echartsType" style="margin-bottom: 0.26042vw">
            <p class="echartsTitle">幼儿出险情况
              <el-tooltip placement="top">
                <div slot="content">
                  8月1日至今出险人数量
                </div>
                <span><img src="@/assets/img/wh.png" alt=""></span>
              </el-tooltip>
            </p>
          </div>
          <div
            class="chartsContent"
            v-show="Object.keys(insuranceChartData).length"
          >
            <div id="insuranceChart"></div>
          </div>
          <div
            class="noEachartData"
            v-if="!Object.keys(insuranceChartData).length"
          >
            暂无数据
          </div>
        </div>
        <!-- 学生流失率 -->
        <div class="echartsItem container-right" style="padding-top: 0.625vw">
          <div class="echartsType">
            <p class="echartsTitle">学生流失率top5
              <el-tooltip placement="top">
                <div slot="content">
                  学期累计（学期划分节点3.1、9.1)<br/>
                  流失率=学校退校人数/（学校目前在校生+退校人数）
                </div>
                <span><img src="@/assets/img/wh.png" alt=""></span>
              </el-tooltip>
            </p>
            <el-radio-group
              v-model="depositProfit"
              @change="changeDepositProfit"
            >
              <el-radio-button label="1">本学期</el-radio-button>
              <el-radio-button label="2">上学期</el-radio-button>
            </el-radio-group>
          </div>
          <div class="chartsContent">
            <div id="runOffChart"></div>
          </div>
        </div>
      </div>

      <div class="container-second">
        <!-- 出勤率 -->
        <div class="echartsItem container-left" style="padding-top: 0.625vw">
          <div class="echartsType">
            <p class="echartsTitle">出勤率
              <el-tooltip placement="top">
                <div slot="content">
                  9月至今每月<br/>
                  本系统出勤率=实际出勤人数/应出勤人数，<br/>
                  实际出勤人次=各园校填写的每天出勤人数之和，<br/>
                  应出勤人次=各园校各班应出勤人数之和，<br/>
                  园校各班应出勤人次=班级人数*出勤天数+转入出勤人次-转出缺勤人次；<br/>
                  青蓝家园出勤率=实际出勤人次/应出勤人次，<br/>
                  应出勤人次=本月每天应出勤人数累加，<br/>
                  实际出勤人次=青蓝家园考勤管理学生考勤记录出勤状态为已出勤的人数；<br/>
                </div>
                <span><img src="@/assets/img/wh.png" alt=""></span>
              </el-tooltip>
            </p>
            <el-radio-group v-model="attendanceRate" @change="changeAttendance">
              <el-radio-button label="1">本系统</el-radio-button>
              <el-radio-button label="2">青蓝家园</el-radio-button>
            </el-radio-group>
          </div>
          <div class="chartsContent">
            <div id="attendanceChart"></div>
          </div>
        </div>
        <!-- 保险在保率 -->
        <div class="echartsItem container-middle">
          <div class="echartsType">
            <p class="echartsTitle">保险在保率</p>
          </div>
          <div class="chartsContent">
            <div id="operateChart"></div>
          </div>
        </div>
        <!-- 疾病防控 -->
        <div class="echartsItem container-right">
          <div class="echartsType">
            <p class="echartsTitle">疾病防控
              <el-tooltip placement="top">
                <div slot="content">
                  本季度累计<br/>
                  所有上报数据（全部状态）
                </div>
                <span><img src="@/assets/img/wh.png" alt=""></span>
              </el-tooltip>
            </p>
          </div>
          <div
            class="chartsContent"
            v-show="Object.keys(contagionChartData).length"
          >
            <div id="contagionChart"></div>
          </div>
          <div
            class="noEachartData"
            v-if="!Object.keys(contagionChartData).length"
          >
            暂无数据
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extension } from "@/utils/validate";
import { mapGetters, mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      depositProfit: 1,
      attendanceRate: 1,
      morChart: null,
      insuranceChart: null,
      runOffChart: null,
      attendanceChart: null,
      operateChart: null,
      contagionChart: null,
      morChartData: { company: [] },
      insuranceChartData: { time: [] },
      contagionChartData: { name: [] },
    };
  },

  created() {},

  mounted() {
    this.getStudentDiseaseRate();
    this.getInsuranceByStudent();
    this.getStudentStudentRunOff(1);
    this.getStudentAttendanceRate(1);
    this.getOperateInsure();
    this.getStudentContagionReport();
    // setTimeout(() => {
    //   this.getStudentDiseaseRate();
    //   this.getInsuranceByStudent();
    //   this.getStudentStudentRunOff(1);
    //   this.getStudentAttendanceRate(1);
    //   this.getOperateInsure();
    //   this.getStudentContagionReport();
    // }, 100);
    window.addEventListener("resize", () => {
      this.morChart.resize();
      this.insuranceChart.resize();
      this.runOffChart.resize();
      this.attendanceChart.resize();
      this.operateChart.resize();
      this.contagionChart.resize();
    });
  },

  methods: {
    //图表数据
    //发病患病率
    getStudentDiseaseRate() {
      this.$api.getStudentDiseaseRate().then((res) => {
        if (res.data.code == 0) {
          // let fillData = res.data.data.fill; //患病
          let morChartData = res.data.data; //发病
          this.morChartData = res.data.data.company;
          this.myChartInit(morChartData);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //学生出险情况
    getInsuranceByStudent() {
      let params = {
        type: 2,
      };
      this.$api.getInsuranceByStudent(params).then((res) => {
        if (res.data.code == 0) {
          let insuranceChartData = res.data.data;
          this.insuranceChartData = res.data.data.time || {};
          this.insuranceDateChartInit(insuranceChartData);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //学生流失率
    getStudentStudentRunOff(type) {
      let params = {
        type: type,
      };
      this.$api.getStudentStudentRunOff(params).then((res) => {
        if (res.data.code == 0) {
          let runOffDate = res.data.data;
          this.runOffChartInit(runOffDate);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //学生出勤率
    getStudentAttendanceRate(type) {
      let params = {
        type: type,
      };
      this.$api.getStudentAttendanceRate(params).then((res) => {
        if (res.data.code == 0) {
          let attendanceDate = res.data.data;
          this.attendanceChartInit(attendanceDate);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //保险在保率
    getOperateInsure() {
      let params = {
        type: 2,
      };
      this.$api.getOperateInsure(params).then((res) => {
        if (res.data.code == 0) {
          let operateDate = res.data.data;
          this.operateDateChartInit(operateDate);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //传染病
    getStudentContagionReport() {
      this.$api.getStudentContagionReport().then((res) => {
        if (res.data.code == 0) {
          let contagionDate = res.data.data;
          this.contagionChartData = res.data.data.xAxis;
          this.contagionDateChartInit(contagionDate);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    myChartInit(morDate) {
      let morChart = this.$echarts.init(document.getElementById("morChart"));

      let morCompany = ["患病率", "发病率"];

      var seriesData = [];
      // for (var i = 0; i < morCompany.length; i++) {
      for (var i = 0; i < morDate.rate.length; i++) {
        var itemStyle = {};
        if (i == 0) {
          itemStyle = {
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [2, 2, 0, 0],
              color: "#004DA0",
            },
          };
        } else if (i == 1) {
          itemStyle = {
            //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [2, 2, 0, 0],
              color: "#E7C69C",
            },
          };
        }
        seriesData.push({
          name: morCompany[i],
          type: "bar",
          data: morDate.rate[i],
          barGap: "30%" /*多个并排柱子设置柱子之间的间距*/,
          // barCategoryGap: "50%" /*多个并排柱子设置柱子之间的间距*/,
          barWidth: 6, //柱图宽度
          itemStyle: itemStyle,
        });
      }
      // }

      // 发病率
      let morChartOption = {
        // title: {
        //   text: "患病率&发病率",
        //   left: 10,
        //   textStyle: {
        //     color: "#3D3E4F", // 主标题文字的颜色。
        //     fontSize: 18, // 字体大小
        //     fontWeight: 400,
        //   },
        // },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            var relVal = params[0].name;
            var names = ["患病率", "发病率"];
            for (var i = 0; i < params.length; i++) {
              relVal +=
                "<br/>" +
                params[i].marker +
                names[i] +
                " " +
                params[i].value +
                "%";
            }
            return relVal;
          },
        },
        legend: {
          data: morCompany,
          show: true,
          itemWidth: 10,
          itemHeight: 10,
          right: 14,
          icon: "circle", //'image://../asset/ico/favicon.png',//标志图形类型，默认自动选择（8种类型循环使用，不显示标志图形可设为'none'），默认循环选择类型有：'circle' | 'rectangle' | 'triangle' | 'diamond' |'emptyCircle' | 'emptyRectangle' | 'emptyTriangle' | 'emptyDiamond'另外，还支持五种更特别的标志图形'heart'（心形）、'droplet'（水滴）、'pin'（标注）、'arrow'（箭头）和'star'（五角星），这并不出现在常规的8类图形中，但无论是在系列级还是数据级上你都可以指定使用，同时，'star' + n（n>=3)可变化出N角星，如指定为'star6'则可以显示6角星
          textStyle: {
            color: "#9290B1",
            fontSize: "14px",
          },
        },
        xAxis: {
          type: "category",
          data: morDate.company,
          triggerEvent: true,
          axisTick: {
            show: false, //不显示坐标轴刻度
          },
          axisLine: {
            show: false, //不显示坐标轴轴线
          },
          axisLabel: {
            rotate: 60,
            textStyle: {
              //改变xy轴上文字的颜色
              color: "#A8A6B9",
            },
            margin: 8,
            formatter:function(params){
              var val="";
              if(params.length >4){
                  val = params.substr(0,4)+'...';
                  return val;
              }else{
                  return params;
              }
            },
          },
        },

        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}%",
            textStyle: {
              color: "#A8A6B9",
            },
          },
          // axisTick: {
          //   show: false  //不显示坐标轴刻度
          // },
          // axisLine: {
          //   show: false  //不显示坐标轴轴线
          // },
        },
        grid: {
          left: "14",
          right: "14",
          bottom: "20",
          containLabel: true,
        },
        series: seriesData,
      };
      morChart.setOption(morChartOption);
      extension(morChart);
      this.morChart = morChart;
    },

    insuranceDateChartInit(insuranceDate) {
      let insuranceChart = this.$echarts.init(
        document.getElementById("insuranceChart")
      );

      var seriesData = [];
      // for (var i = 0; i < insuranceDate["type"].length; i++) {
      for (var i = 0; i < insuranceDate.insuranceNum.length; i++) {
        var itemStyle = {};
        if (i == 0) {
          itemStyle = {
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [2, 2, 0, 0],
              color: "#00AB9F",
            },
          };
        } else if (i == 1) {
          itemStyle = {
            //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [2, 2, 0, 0],
              color: "#004DA0",
            },
          };
        } else if (i == 2) {
          itemStyle = {
            //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [2, 2, 0, 0],
              color: "#C5C3D8",
            },
          };
        }

        seriesData.push({
          name: insuranceDate.type[i],
          type: "bar",
          data: insuranceDate.insuranceNum[i],
          barGap: "30%" /*多个并排柱子设置柱子之间的间距*/,
          // barCategoryGap: "50%" /*多个并排柱子设置柱子之间的间距*/,
          barWidth: 5, //柱图宽度
          itemStyle: itemStyle,
        });
      }
      // }

      //出险情况
      let insuranceChartOption = {
        // title: {
        //   text: "幼儿出险情况",
        //   left: 10,
        //   textStyle: {
        //     color: "#3D3E4F", // 主标题文字的颜色。
        //     fontSize: 18, // 字体大小
        //     fontWeight: 400,
        //   },
        // },
        // tooltip: {
        //   trigger: "axis",
        //   // formatter: "{b}月{a}<br/>{c}%", // 这里是鼠标移上去的显示数据
        //   formatter: function (params) {
        //     console.log(params, "都有什么");
        //     var result = "";
        //     var dotHtml =
        //       '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:rgba(0, 77, 160, 1)"></span>';
        //     params.forEach(function (item) {
        //       result += item.axisValue + "</br>" + dotHtml + item.data;
        //     });
        //     return result;
        //   },

        //   backgroundColor: "rgba(9, 12, 21, 0.8)",
        //   borderColor: "rgba(9, 12, 21, 0.8)",
        //   textStyle: {
        //     fontSize: 14,
        //     textAlign: "center",
        //     color: "#fff",
        //   },
        //   axisPointer: {
        //     type: "shadow",
        //   },
        // },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
          },
        },

        legend: {
          data: insuranceDate.type,
          show: true,
          itemWidth: 10,
          itemHeight: 10,
          right: 14,
          icon: "circle", //'image://../asset/ico/favicon.png',//标志图形类型，默认自动选择（8种类型循环使用，不显示标志图形可设为'none'），默认循环选择类型有：'circle' | 'rectangle' | 'triangle' | 'diamond' |'emptyCircle' | 'emptyRectangle' | 'emptyTriangle' | 'emptyDiamond'另外，还支持五种更特别的标志图形'heart'（心形）、'droplet'（水滴）、'pin'（标注）、'arrow'（箭头）和'star'（五角星），这并不出现在常规的8类图形中，但无论是在系列级还是数据级上你都可以指定使用，同时，'star' + n（n>=3)可变化出N角星，如指定为'star6'则可以显示6角星
          textStyle: {
            color: "#9290B1",
            fontSize: "14px",
          },
        },
        xAxis: {
          type: "category",
          data: insuranceDate.time,
          axisTick: {
            show: false, //不显示坐标轴刻度
          },
          axisLine: {
            show: false, //不显示坐标轴轴线
          },
          axisLabel: {
            rotate: 30,
            textStyle: {
              //改变xy轴上文字的颜色
              color: "#A8A6B9",
            },
          },
        },

        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#A8A6B9",
            },
          },
          // axisTick: {
          //   show: false  //不显示坐标轴刻度
          // },
          // axisLine: {
          //   show: false  //不显示坐标轴轴线
          // },
        },
        grid: {
          left: "14",
          right: "14",
          bottom: "10",
          containLabel: true,
        },
        series: seriesData,
      };
      insuranceChart.setOption(insuranceChartOption);
      this.insuranceChart = insuranceChart;
    },

    runOffChartInit(runOffDate) {
      let runOffChart = this.$echarts.init(
        document.getElementById("runOffChart")
      );
      let runOffChartOption = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: function (val) {
            return (
              val[0].marker +
              '<span style="width:20px;display: inline-block;"></span>' +
              val[0].name +
              '<span style="width:20px;display: inline-block;"></span>' +
              val[0].value +
              "%"
            );
          },
        },
        xAxis: {
          type: "value",
          axisTick: {
            show: false, //不显示坐标轴刻度
          },
          axisLine: {
            show: false, //不显示坐标轴轴线
          },

          axisLabel: {
            formatter: "{value}%",
            // rotate:30,
            textStyle: {
              //改变xy轴上文字的颜色
              color: "#A8A6B9",
            },
          },
        },
        yAxis: {
          type: "category",
          data: runOffDate.name,
          triggerEvent: true,
          axisLabel: {
            textStyle: {
              color: "#A8A6B9",
            },
             margin: 8,
              formatter:function(params){
                var val="";
                if(params.length >4){
                    val = params.substr(0,4)+'...';
                    return val;
                }else{
                    return params;
                }
              },
          },
          axisTick: {
            show: false, //不显示坐标轴刻度
          },
          axisLine: {
            show: false, //不显示坐标轴轴线
          },
        },
        grid: {
          top: "20",
          left: "14",
          right: "42",
          bottom: "40",
          containLabel: true,
        },
        series: [
          {
            // data: ['郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园'],
            data: runOffDate.rate,
            type: "bar",
            showBackground: true,
            // bottom:10,
            barWidth: 20, //柱图宽度
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [0, 4, 4, 0],
                color: function (p) {
                  if (p.value >= 100) {
                    return "#E7C69C";
                  } else {
                    return "#004DA0";
                  }
                }, //柱形背景颜色
                label: {
                  formatter: "{c}" + "%",
                  show: true,
                  position: "right",
                  textStyle: {
                    fontSize: "14",
                    color: "#9290B1",
                  },
                },
              },
            },
            backgroundStyle: {
              color: "#F3F3FB",
            },
          },
        ],
      };
      runOffChart.setOption(runOffChartOption);
      extension(runOffChart);
      this.runOffChart = runOffChart;
    },

    changeDepositProfit(val) {
      if (val == 1) {
        this.getStudentStudentRunOff(1);
      } else {
        this.getStudentStudentRunOff(2);
      }
    },

    changeAttendance(val) {
      if (val == 1) {
        this.getStudentAttendanceRate(1);
      } else {
        this.getStudentAttendanceRate(2);
      }
    },

    attendanceChartInit(attendanceDate) {
      let attendanceChart = this.$echarts.init(
        document.getElementById("attendanceChart")
      );

      // 绘制星期-出险数量对比图图表
      var attendanceChartOption = {
        color: ["#004DA0"],
        // title: {
        //   text: "星期-出险数量对比图",
        //   left: 20,
        //   textStyle: {
        //     color: "#3D3E4F", // 主标题文字的颜色。
        //     fontSize: 18, // 字体大小
        //     fontWeight: 400,
        //   },
        // },
        tooltip: {
          trigger: "axis",
          // formatter: "{b}月{a}<br/>{c}%", // 这里是鼠标移上去的显示数据
          formatter: function (params) {
            var result = "";
            var dotHtml =
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:rgba(0, 77, 160, 1)"></span>';
            params.forEach(function (item) {
              result +=
                item.axisValue +
                "月出勤率" +
                "</br>" +
                dotHtml +
                item.data +
                "%";
            });
            return result;
          },
          // formatter: function(param) {
          //   return (
          //     param.marker +
          //     " " +
          //     "<br>" +
          //     "&nbsp;&nbsp;&nbsp;&nbsp;" +
          //     param.name +
          //     "：" +
          //     param.value +
          //     "<br>"
          //   );
          // },
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "35",
          left: "14",
          right: "20",
          bottom: "45",
          containLabel: true,
        },
        // xAxis: {
        //   type: "category",
        //   data: weekData.xList,
        // },
        xAxis: [
          {
            type: "category",
            data: attendanceDate.date,
            axisLabel: {
              color: "#A8A6B9",
            },
            /*改变x轴颜色*/
            axisLine: {
              lineStyle: {
                color: "#EEEFF5",
                width: 1, //这里是为了突出显示加上的
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}%",
              color: "#A8A6B9",
            },
            // 分割线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEFF5",
                // type: "dashed", //虚线
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: "出勤率",
            type: "bar",
            barWidth: "20",
            data: attendanceDate.rate,
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [4, 4, 0, 0],
              },
            },
          },
        ],
      };
      attendanceChart.setOption(attendanceChartOption);
      this.attendanceChart = attendanceChart;
    },

    operateDateChartInit(operateDate) {
      let operateChart = this.$echarts.init(
        document.getElementById("operateChart")
      );

      // 绘制保险在保率图表
      let operateChartOption = {
        tooltip: {
          trigger: "item",
          show: false,
        },
        legend: {
          orient: "vertical",
          show: true,
          top: "40%",
          left: "68%",
          icon: "circle",
          textStyle: {
            color: "#9290B1",
            lineHeight: 30,
            fontSize: 14,
          },
          selectedMode: false,
        },
        color: ["#004DA0", "#C5C3D8"],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["45%", "60%"],
            center: ["35%", "48%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                formatter: function (params) {
                  let ele = `{a|${params.percent}%} \n {b| ${params.name}}`;
                  return ele;
                },
                rich: {
                  a: {
                    fontSize: 20,
                    textAlign: "center",
                    color: "#000",
                  },
                  b: {
                    fontSize: 12,
                    textAlign: "center",
                    color: "#6C7293",
                    padding: [4, 4, 0, 0],
                  },
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: operateDate.inInsure, name: "在保率" },
              { value: operateDate.outInsure, name: "未在保率" },
            ],
          },
        ],
      };
      operateChart.setOption(operateChartOption);

      let dataCountData = [
        { value: operateDate.inInsure, name: "在保率" },
        { value: operateDate.outInsure, name: "未在保率" },
      ];
      let dataCount = dataCountData.length;
      // 设置默认展示，seriesIndex：系列名，dataIndex：当前索引
      operateChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: 0,
      });
      // 鼠标进入高亮当前，取消其他高亮
      operateChart.on("mouseover", (e) => {
        operateChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
        // 根据当前option中data的length，遍历取消其他高亮
        for (let i = 0; i < dataCount; i++) {
          if (i != e.dataIndex) {
            operateChart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: i,
            });
          }
        }
      });
      //鼠标移出后显示之前默认高亮的那块，其他取消高亮
      operateChart.on("mouseout", (e) => {
        if (e.dataIndex !== 0) {
          operateChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: e.dataIndex,
          });
        } else {
          operateChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }
      });
      this.operateChart = operateChart;
    },

    contagionDateChartInit(contagionDate) {
      let contagionChart = this.$echarts.init(
        document.getElementById("contagionChart")
      );

      let contagionChartOption = {
        color: ["#004DA0"],
        // title: {
        //   text: "星期-出险数量对比图",
        //   left: 20,
        //   textStyle: {
        //     color: "#3D3E4F", // 主标题文字的颜色。
        //     fontSize: 18, // 字体大小
        //     fontWeight: 400,
        //   },
        // },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "35",
          left: "14",
          right: "20",
          bottom: "5",
          containLabel: true,
        },
        // xAxis: {
        //   type: "category",
        //   data: weekData.xList,
        // },
        xAxis: [
          {
            type: "category",
            data: contagionDate.xAxis,
            triggerEvent: true,
            axisLabel: {
              rotate: 60,
              color: "#A8A6B9",
              margin: 8,
              formatter:function(params){
                var val="";
                if(params.length >4){
                    val = params.substr(0,4)+'...';
                    return val;
                }else{
                    return params;
                }
              },
            },
            /*改变x轴颜色*/
            axisLine: {
              lineStyle: {
                color: "#EEEFF5",
                width: 1, //这里是为了突出显示加上的
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              color: "#A8A6B9",
            },
            // 分割线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEFF5",
                // type: "dashed", //虚线
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "20",
            data: contagionDate.yAxis,
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [4, 4, 0, 0],
              },
            },
          },
        ],
      };
      contagionChart.setOption(contagionChartOption);
      extension(contagionChart);
      this.contagionChart = contagionChart;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.morChart.resize();
      this.insuranceChart.resize();
      this.runOffChart.resize();
      this.attendanceChart.resize();
      this.operateChart.resize();
      this.contagionChart.resize();
    });
  },
};
</script>

<style lang="scss" scoped>
.dict {
  width: calc(100% - 60px);
  background-color: #f4f4fc;
  // height: calc(100% - 40px);
  padding: 34px 30px 30px 30px;

  .boardTitle {
    color: #3d3e4f;
    font-size: 22px;
    font-weight: bold;
  }

  .tableList {
    height: calc(100% - 62px);
  }

  .container {
    width: 100%;
    height: calc(100% - 22px);
    .echartsItem {
      background-color: #fff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      border-radius: 8px;
      height: 380px;
      margin-top: 30px;
      .echartsTitle {
        // padding: 28px 30px;
        font-size: 18px;
        color: #26262e;
      }
    }

    .chartsContent {
      width: calc(100% - 20px);
      height: calc(100% - 43px);
      padding: 10px;
      overflow: auto;
    }

    #morChart,
    #insuranceChart,
    #runOffChart,
    #attendanceChart,
    #operateChart,
    #contagionChart {
      width: 100%;
      height: 100%;
    }

    .container-first,
    .container-second {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .container-first {
      margin-top: 20px;
      .echartsItem {
        margin-top: 0;
      }
    }

    .container-second {
      // margin-top: 20px;
    }

    .container-left {
      width: 535px;
      height: 380px;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      opacity: 1;
      border-radius: 8px;
      margin-right: 30px;
      padding-top: 20px;
      box-sizing: border-box;
    }

    .container-middle {
      width: 450px;
      height: 380px;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      opacity: 1;
      border-radius: 8px;
      margin-right: 30px;
      padding-top: 20px;
      box-sizing: border-box;
    }

    .container-right {
      width: 550px;
      height: 380px;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      opacity: 1;
      border-radius: 8px;
      padding-top: 20px;
      box-sizing: border-box;
    }

    .echartsType {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;

      .echartsTitle {
        padding: 0 !important;
        font-size: 18px;
      }
    }
  }

  /deep/ .el-radio-button__inner {
    padding: 6px 6px;
  }

  .noEachartData {
    height: calc(100% - 74px);
    width: 100%;
    // height: 100%;
    // text-align: center;
    color: #999;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/div {
      display: none;
    }
  }
}
</style>
